export interface Booking {
    startDate: Date
    endDate: Date
    summary: string
}

export function stringToDate(dateString: string): Date {
    const year = +(dateString.substring(0,4))
    const month = +(dateString.substring(4,6))
    const day = +(dateString.substring(6,8))
    return new Date(year, month-1, day);
}

export function isBooked(currentDate: Date, bookings: Booking[]): boolean {
    return bookings.some(booking => booking.startDate <= currentDate && currentDate <= booking.endDate)
}