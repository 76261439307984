import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { RentalHomeService } from '../rental-home.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators'
import { CalendarEvent, CalendarMonthViewBeforeRenderEvent } from 'angular-calendar'
import { Booking, isBooked } from '../booking';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit, OnDestroy {

  constructor(private rentalHomeService: RentalHomeService) { }

  calendar$ = new BehaviorSubject<Booking[]>([])
  destroyed$ = new Subject<boolean>()

  viewDate: Date = new Date();
  activeDay: Date = new Date();

  events: CalendarEvent[] = [];

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
    renderEvent.body.forEach(day => {
      if (isBooked(day.date, this.calendar$.value)) {
        day.cssClass = 'reserved';
      }
    });
  }

  ngOnInit() {
    this.rentalHomeService.getCalendar().pipe(
      takeUntil(this.destroyed$),
    ).subscribe(bookings => this.calendar$.next(bookings))

    this.galleryOptions = [
      {
        thumbnailsColumns: 2,
        thumbnailsRows: 1,
        thumbnailsPercent: 40,
        imagePercent: 60,
        thumbnailMargin: 2,
        thumbnailsMargin: 2,
        imageAnimation: NgxGalleryAnimation.Slide
      },
      {
        width: '300px',
        height: '300px',
        thumbnailsColumns: 2,
        fullWidth: true,
      },
      {
        width: '100%',
        height: '800px',
        thumbnailsColumns: 2
      },
    ];

    this.galleryImages = []

    for (let i = 1; i < 44; i++) {
      this.galleryImages.push({
        small: '../../assets/gallery/' + i + '.jpg',
        medium: '../../assets/gallery/' + i + '.jpg',
        big: '../../assets/gallery/' + i + '.jpg'
      })
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
  }

}
