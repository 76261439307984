import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map, mapTo } from 'rxjs/operators'
import icsToJson from './icsToJson'
import { Booking } from './booking'

@Injectable({
    providedIn: 'root',
})
export class RentalHomeService {

    constructor(private http: HttpClient) { /* do nothing */ }
  
    getCalendar(): Observable<Booking[]> {
        const httpOptions = {
            headers: new HttpHeaders({
            }),
            responseType: 'text' as 'json',
        }
        return this.http.get('https://cors-anywhere.herokuapp.com/https://www.vrbo.com/icalendar/d259859606c44c9f971f8bd369b21c2b.ics?nonTentative', httpOptions).pipe(
            map(response => icsToJson(response)),
        )
    }

}